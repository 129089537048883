import "./App.css";
import "tailwindcss/tailwind.css";
import "react-quill/dist/quill.snow.css";
import "react-quill/dist/quill.bubble.css";
import { Route, Routes, useLocation } from "react-router-dom";
import Navbar from "./layout/Navbar";
import { useEffect } from "react";
import { loadUser } from "./services/actions/auth";
import store from "./services/store/store";
import PrivateRoute from "./utils/PrivateRoute";
import LoginPage from "./pages/Auth";
import { Toaster } from "react-hot-toast";
import AdminBooksPage from "./pages/Admin/Books";
import AdminBookChaptersPage from "./pages/Admin/bookChapters";
import AdminConferencePage from "./pages/Admin/conferences";
import AdminJournalArticlesPage from "./pages/Admin/journalArticles";
import AdminBlogsPage from "./pages/Admin/blogs";
import AdminExternalLinksPage from "./pages/Admin/externalLinks";
import AdminAboutPage from "./pages/Admin/about";
import Footer from "./container/Footer";
import BooksPage from "./pages/books";
import Bookchapters from "./pages/bookchapters";
import JournalArticles from "./pages/journalArticles";
import Conferences from "./pages/conferences";
import Blogs from "./pages/blogs";
import NotFound from "./pages/notfound";
import BlogById from "./container/BlogById";
import AboutPage from "./pages/about";

function App() {
  useEffect(() => {
    store.dispatch(loadUser());
  }, []);
  const location = useLocation();

  const shouldShowMenu = () => {
    const excludedRoutes = [
      "/admin/books",
      "/admin/book-chapters",
      "/admin/articles",
      "/admin/blogs",
      "/admin/conferences",
      "/admin/links",
      "/admin/about",
    ];
    return !excludedRoutes.includes(location.pathname);
  };
  return (
    <div className="flex flex-col min-h-screen">
      {shouldShowMenu() && <Navbar />}
      <Toaster />
      <Routes>
        <Route path="/" element={<Blogs />} />
        <Route path="/books" element={<BooksPage />} />
        <Route path="/articles" element={<JournalArticles />} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/blogs/:id" element={<BlogById />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/book-chapters" element={<Bookchapters />} />
        <Route path="/conferences" element={<Conferences />} />
        <Route path="/admin" element={<LoginPage />} />
        <Route
          path="/admin/books"
          element={
            <PrivateRoute>
              <AdminBooksPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/book-chapters"
          element={
            <PrivateRoute>
              <AdminBookChaptersPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/articles"
          element={
            <PrivateRoute>
              <AdminJournalArticlesPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/conferences"
          element={
            <PrivateRoute>
              <AdminConferencePage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/blogs"
          element={
            <PrivateRoute>
              <AdminBlogsPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/admin/links"
          element={
            <PrivateRoute>
              <AdminExternalLinksPage />
            </PrivateRoute>
          }
        />

        <Route
          path="/admin/about"
          element={
            <PrivateRoute>
              <AdminAboutPage />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>

      {shouldShowMenu() && <Footer />}
    </div>
  );
}

export default App;
